import styled from "styled-components";
import { Typography } from "components/UI";
import { Grid } from "@mui/material";

export const Container = styled.div` 
  max-width: 600px;
  min-height: 600px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const MainContainer = styled.section` 
padding: 100px 25px;
display: flex;
justify-content: center;
align-items: center;
`;

export const Title = styled(Typography).attrs({
  size: "xl"
})`
`;

export const Subtitle = styled(Typography).attrs({
  size: "m"
})`
  font-weight: ${(props) => props.theme.fonts.weight.bold};
`;

export const Text = styled(Typography).attrs({
  size: "m"
})`
  font-weight: ${(props) => props.theme.fonts.weight.regular};
`;

export const Row = styled(Grid).attrs(({
  direction: "row",
  container: true,
  justify: "space-between"
}))`     
`;

export const Col = styled(Grid).attrs(({
  item: true,
  container: true,
  direction: "column"
}))`     
`;
