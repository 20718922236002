import React, { useState } from 'react';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import * as S from './style';
import { useForm, Controller } from 'react-hook-form';
import { Input } from 'components/UI/Common/LandingPage';
import PhoneInput from '../PhoneInput';
import InputWrapper from './InputWrapper';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Toast } from 'components/UI';
import PropTypes from "prop-types";

const YoutubeEmbed = ({ embedId }) => (
  <div className="video-responsive" style={{
    overflow: 'hidden',
    paddingBottom: '56.25%',
    position: 'relative',
    height: 0
  }}>
    <iframe
      width="853"
      height="480"
      src={`https://www.youtube.com/embed/${embedId}`}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
      title="Embedded youtube"
      style={{
        left: 0,
        top: 0,
        height: '100%',
        width: '100%',
        position: 'absolute'
      }}
    />
  </div>
);

YoutubeEmbed.propTypes = {
  embedId: PropTypes.string.isRequired
};


const ContactForm = ({ content, id }) => {
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors, dirtyFields },
  } = useForm({ mode: 'onSubmit' });
  // const [sumbitting, setSumbitting] = useState(false);

  const { t } = useTranslation();

  let inputs = [
    // { name: 'first_name', type: 'text', label: content.first_name },
    // { name: 'last_name', type: 'text', label: content.last_name },
    // { name: 'email', type: 'email', label: content.email },
    // { name: 'phone', type: 'phone', label: content.phone },
    // {
    //   name: 'select_employees',
    //   type: 'select',
    //   label: content.select_employees,
    //   placeholder: t('Please select'),
    //   selectItems: [
    //     { value: '1', label: '1' },
    //     { value: '2-10', label: '2-10' },
    //     { value: '11-100', label: '11-100' },
    //     { value: '101-1000', label: '101-1000' },
    //     { value: '1000+', label: '1000+' },
    //   ],
    // },
    // { name: 'message', type: 'textarea', label: content.message },
  ];
  const [submitting, setSubmitting] = useState(false);

  function onSubmit() {
    window.open('https://app.theos.ai/authentication/signup', '_self');
  }

  return (
    <S.ContactContainer id={id}>
      <Toast />
      <S.Title>{content.title}</S.Title>
      <S.Description>{content.description}</S.Description>
        <S.ButtonContainer>
          <S.ContactButton
            name='submit'
            type='submit'
            onClick={onSubmit}
            loading={submitting ? 1 : undefined}
          >
            {t('Sign Up')}
          </S.ContactButton>
        </S.ButtonContainer>
    </S.ContactContainer>
  );
};

export default ContactForm;
