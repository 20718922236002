import * as React from 'react';
import UniversitySuccess from 'components/landing-page/UniversitySuccess';
import { graphql } from 'gatsby';

const UniversitySuccessPage = ({}) => {
  return <UniversitySuccess/>
};

export default UniversitySuccessPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;

